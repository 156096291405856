// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAsvycSIinLHY2ahsayWlH3QNQBOQwaInM",
    authDomain: "careers-bangladesh.firebaseapp.com",
    projectId: "careers-bangladesh",
    storageBucket: "careers-bangladesh.appspot.com",
    messagingSenderId: "444909047472",
    appId: "1:444909047472:web:a8e38ba46751cd68277575",
    measurementId: "G-MHHHYS7RT5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;