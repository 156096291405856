import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className=" d-flex my-5 justify-content-center align-items-center">
      <div className=" fs-5 loader fw-bold mt-2"> </div>
    </div>
  );
};

export default Loader;
