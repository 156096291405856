import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FaFile, FaFilter, FaList, FaSearch, FaStar, FaTh, } from "react-icons/fa";
import useTitle from "../../hooks/useTitle";
import { useForm } from "react-hook-form";
import "./FindJob.css";

const FindJobsByCategory = () => {
  useTitle("Category Job");
  const [jobs, setJobs] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const { categoryId } = useParams();

  const pages = Math.ceil(count / size);

  console.log("page and pages : ", page, pages);


  useEffect(() => {
    fetch(`${process.env.REACT_APP_CABD_SERVER}/jobbycategory?category=${categoryId}&page=${page}&size=${size}`)
      .then(response => response.json())
      .then(data => {
        setCount(data.count);
        console.log("Find Jobs by Category:", data.count);
        setJobs(data.job);
      });
  }, [categoryId, page, size])

  // const jobs = useLoaderData();

  const findJobsNo = jobs.length;

  const handleNextPage = () => {
    if (page < pages - 1) {
      setPage(page + 1)
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };


  return (
    <div className="row">
      <div className="col-md-12">
        <div className="grid search">
          <div className="grid-body">
            <div className="row">

              <div className="mt-md-0 mt-5 ">

                {findJobsNo > 0 ? <> <h2 className=" text-center fw-bold">All Job in this Category</h2>  </> : <></>}

                <div className="padding"></div>

                <div className="table-responsive">
                  <table className="table table-hover  table-bordered">
                    {findJobsNo > 0 ? (
                      <>
                        <thead>
                          <tr className="table-secondary text-center">
                            <th>SL</th>
                            <th>Title</th>
                            <th>Organization</th>
                            <th>Work Place</th>
                            <th>Posted</th>
                            <th>DeadLine</th>
                            <th>Salary</th>
                          </tr>
                        </thead>

                        <tbody>
                          {jobs.map((job, index) => (
                            <tr key={job._id} className="tableData">
                              <td className="text-center">{index + 1 + page * size}</td>
                              <td className="fw-bold">
                                <Link
                                  className=" text-decoration-none text-primary "
                                  to={`/jobDetails/${job._id}`}
                                >
                                  {job.jobTitle}
                                </Link>
                              </td>
                              <td className=" d-flex justify-content-between align-items-center">
                                {job.organization}
                                <img className=" rounded-5" src={job?.companyLogo} alt="" />
                              </td>
                              <td>{job.location}</td>
                              <td className=" text-center">{job.postDate}</td>
                              <td className=" text-center">{job.deadLine}</td>
                              <td className=" text-center">৳{job.salaryTo}</td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    ) : (
                      <>
                        <p className=" fs-4 my-5 text-center  ">
                          No jobs in this category, please search with a different category.
                        </p>
                      </>
                    )}
                  </table>
                </div>

                {findJobsNo > 0 ? (
                  <>
                    {findJobsNo > 1 ? (
                      <>
                        <p className=" fs-5 text-success   text-center fw-bold">
                          Total {count} jobs found in this category
                        </p>
                      </>
                    ) : (
                      <>
                        <p className=" fs-5 text-success   text-center fw-bold">
                          {findJobsNo} job found in this category
                        </p>
                      </>
                    )}


                  </>
                ) : (
                  <></>
                )}



                {/* pagnation start */}
                {(pages > 1) && <>
                  <div className="col-lg-8 mx-auto  d-flex justify-content-between align-items-center" >

                    <div className=" d-flex">
                      <button className="btn btn-primary btn-sm fw-bold" onClick={handlePreviousPage}> <b className=""> &#8249;</b>Prev</button>
                      <button className="btn btn-primary btn-sm ms-3 fw-bold" onClick={handleNextPage}>Next&#8250;</button>
                    </div>

                    <div className=" d-flex justify-content-center align-items-center">
                      <h6 className=" mt-1 fw-bold fst-italic">Jobs_per_page </h6>
                      <select className=" jobsPerPage ms-1" onChange={event => setSize(event.target.value)}>
                        <option value="10">10</option>
                        <option value="20" selected>20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>

                    </div>


                    <div className=" pageBtn d-flex justify-content-center align-items-center my-1">
                      <h5 className=" me-2">Pages:  </h5>
                      {
                        [...Array(pages).keys()].map(number => {
                          if (pages > 6 && number === 5 && pages > 7) {
                            return <p className=" fw-bold" key={number}>...</p>;
                          } else if (pages > 6 && number > 5 && number !== pages - 1) {
                            return null;
                          } else {
                            return (
                              <h5
                                key={number}
                                className={page === number && 'selected'}
                                onClick={() => setPage(number)}
                              >
                                {number + 1}&nbsp;&nbsp;
                              </h5>
                            );
                          }
                        })
                      }
                    </div>
                  </div>

                </>}

                {/* pagnation end */}



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindJobsByCategory;