import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Authentication/AuthProvider";
import Loader from "../../Shared/Loader/Loader";

const NewJobPost = () => {
  const { user } = useContext(AuthContext);
  const [companyData, seTCompanyData] = useState({});
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors }, } = useForm();

  const { data: categories, isLoading } = useQuery({
    queryKey: ["category"],
    queryFn: async () => {
      const res = await fetch(`${process.env.REACT_APP_CABD_SERVER}/jobCategories`);
      const data = await res.json();
      return data;
    },
  });

  if (isLoading) {
    <Loader></Loader>
  }

  const jobPostDate = new Date().toJSON().slice(0, 10);


  useEffect(() => {
    fetch(`${process.env.REACT_APP_CABD_SERVER}/employer/${user?.email}`)
      .then(response => response.json())
      .then(data => {
        // console.log("EmployerProfileData", data)
        seTCompanyData(data);
      })
  }, [user?.email])

  // addressBng addressEng  busiDescription companyLogo companyNameBn companyNameEn companySize contactPersonDesignation 
  // contactPersonEmail contactPersonName contactPersonPhone email estdYear name organizationType tradeLicense websiteURL


  const handleJobPost = (data) => {
    const jobPost = {
      email: user?.email,
      postersName: user?.displayName,
      organization: companyData?.companyNameEn,
      orgaType: companyData?.organizationType,
      companyLogo: companyData?.companyLogo,


      category: data.category,
      jobTitle: data.job_title,
      location: data.location,
      vacancies: data.vacancies,
      education: data.education,
      experience: data.experience,
      ageLimitFrom: data.ageLimitFrom,
      ageLimitTo: data.ageLimitTo,
      applicableFor: data.applicableFor,

      postDate: jobPostDate,
      deadLine: data.deadline,
      applyStatus: data.apply_status,
      employmentStatus: data.employment_status,
      businessDescription: data.business_description,
      jobLevel: data.job_level,
      workPlace: data.work_place,
      jobContext: data.job_context,
      jobResponst: data.job_responst,

      workdayFrom: data.workday_from,
      workdayTo: data.workday_to,
      worktimeFrom: data.worktime_from,
      worktimeTo: data.worktime_to,

      salaryFrom: data.salary_from,
      salaryTo: data.salary_to,
      salary: data.salary,

      yearlyBonus: data.yearly_bonus,
      salaryReview: data.salary_review,
      benifitsOne: data.benifits_one,
      benifitsTwo: data.benifits_two,
      benifitsThree: data.benifits_three,
      benifitsFour: data.benifits_four,
      benifitsFive: data.benifits_five,
      benifitsSix: data.benifits_six,

      others: data.others,
      status: data.status,
    };
    console.log("New Job Post Data :", jobPost);


    fetch(`${process.env.REACT_APP_CABD_SERVER}/jobs`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(jobPost),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        toast("New Job Posted Successfully");
        navigate("/dashboard/postedJobList");
      });

  }



  return (
    <div>
      <h2 className=" text-center fw-bold my-3">New Job Post</h2>


      <form onSubmit={handleSubmit(handleJobPost)}>

        <p className="d-flex justify-content-end my-0 ">
          <span className="star">&#x2605; </span>{" "}
          <b>(Red Star) denotes must be filled</b>
        </p>


        <div className=" row">
          <div className=" col-md-6 my-2">
            <label className="label">
              <span className="label-text fw-bold">Company Name</span>{" "}
            </label>
            <input
              defaultValue={companyData?.companyNameEn}
              name="organization"
              className="input form-control"
              id="organization"
              type="text"
              disabled
            />
          </div>

          <div className=" col-md-3 my-2">
            <label htmlFor="company_logo">
              <b> Company Type</b>
            </label>
            <input
              defaultValue={companyData?.organizationType}
              name="organizationType"
              className="input form-control"
              id="organizationType"
              type="text"
              disabled
            />
          </div>

          <div className=" col-md-3 my-2">
            <label className="label" htmlFor="company_logo">
              <span className="label-text fw-bold">Company Logo</span>{" "}
            </label>
            <input
              defaultValue={companyData?.companyLogo}
              name="company_logo"
              className="input form-control"
              id="company_logo"
              type="file"
              disabled
            />
          </div>
        </div>
        <div>
          <label className="label" htmlFor="category">
            <span className="label-text fw-bold">Job Category</span><span className="star">&#x2605;</span>
          </label>
          <select
            {...register("category")}
            name="category"
            type="text"
            className="form-select"
          >
            {categories &&
              categories.map((category, index) => (
                <option key={index} value={category._id}>
                  {category.name}
                </option>
              ))}
          </select>
        </div>

        <div className="row">
          <div className=" col-md-6 my-2 ">
            <label className="label">
              <span className="label-text fw-bold">Job Title</span><span className="star">&#x2605;</span>
            </label>
            <input
              {...register("job_title", { required: true })}
              name="job_title"
              className="input form-control"
              id="job_title"
              type="text"
              placeholder="Enter Job Title"
            />
          </div>
          <div className=" col-md-6 my-2 ">
            <label className="label">
              <span className="label-text fw-bold">Location</span><span className="star">&#x2605;</span>
            </label>
            <input
              {...register("location", { required: true })}
              name="location"
              className="input form-control"
              id="location"
              type="text"
              placeholder="Enter Job Location"
            />
          </div>
        </div>

        <div className="row">
          <div className=" col-lg-4">
            <label className="label">
              <span className="label-text fw-bold">Vacancies</span><span className="star">&#x2605;</span>
            </label>
            <input
              {...register("vacancies", { required: true })}
              name="vacancies"
              className="input form-control"
              id="vacancies"
              type="text"
              placeholder="No of Vacancies"
            />
          </div>

          <div className=" col-lg-4">
            <label className="label">
              <span className="label-text fw-bold">Job Level</span>
            </label>
            <select
              {...register("job_level")}
              name="job_level"
              className="form-select"
            >
              <option>Select Job Level</option>
              <option value={"Entry"}>Entry</option>
              <option value={"Mid"}>Mid</option>
              <option value={"Top"}>Top</option>
            </select>
          </div>

          <div className=" col-lg-4">
            <label className="label">
              <span className="label-text fw-bold">Work Place</span>
            </label>
            <select
              {...register("work_place")}
              name="work_place"
              className="form-select"
            >
              <option>Select Work Place</option>
              <option value="Work at Office">Work at Office</option>
              <option value="Work from Home">Work from Home</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>
        </div>

        <div className="row my-lg-4">
          <div className=" col-lg-4">
            <label className="label">
              <span className="label-text fw-bold">Employment Status</span>
            </label>
            <select
              {...register("employment_status")}
              name="employment_status"
              className=" form-select "
            >
              <option>Select</option>
              <option value={"Full Time"}>Full Time</option>
              <option value={"Part Time"}>Part Time</option>
              <option value={"Contactual Time"}>Contactual Time</option>
              <option value={"Internship"}>Internship</option>
              <option value={"Freelance"}>Freelance</option>
            </select>
          </div>

          <div className="col-lg-4">
            <label className="label">
              <span className="label-text fw-bold">Deadline</span><span className="star">&#x2605;</span>
            </label>
            <input
              {...register("deadline", { required: true })}
              name="deadline"
              className="input form-control ms-1"
              id="deadline"
              type="date"
              placeholder="Application Deadline"
            />
          </div>

          <div className=" col-lg-4">
            <label className="label">
              <span className="label-text fw-bold">Apply Status</span><span className="star">&#x2605;</span>
            </label>
            <select
              name="apply_status"
              {...register("apply_status")}
              className=" form-select"
            >
              <option>Select</option>
              <option value="Apply Online">Apply Online</option>
              <option value="email">Email</option>
              <option value="Hard Copy">Heard Copy</option>
              <option value="Interview">Walk in Interview</option>
            </select>
          </div>
        </div>
        <div className=" my-2 ">
          <label className="label">
            <span className="label-text fw-bold">Education Qualification</span><span className="star">&#x2605;</span>
          </label>
          <input
            {...register("education", { required: true })}
            name="education"
            className="input form-control ms-1"
            id="education"
            type="text"
            placeholder="Enter Education Qualification"
          />
        </div>

        <div className="my-2 ">
          <label className="label">
            <span className="label-text fw-bold">Experience</span><span className="star">&#x2605;</span>
          </label>
          <textarea
            {...register("experience", { required: true })}
            name="experience"
            className="input form-control ms-1"
            id="experience"
            type="text"
            placeholder="Enter experience data"
            maxLength={250}
          />
        </div>


        <div className="row my-3">
          <label className="label">
            <span className="label-text fw-bold">Additional Requirements</span>
          </label>
          <div className=" col-md-4  ">
            <label className="label">
              <span className="label-text fw-bold">Age Limit From</span>
            </label>
            <input
              {...register("ageLimitFrom")}
              name="ageLimitFrom"
              className="input form-control"
              id="ageLimitFrom"
              type="text"
              placeholder="Enter age limit from"
            />
          </div>
          <div className=" col-md-4  ">
            <label className="label">
              <span className="label-text fw-bold">Age Limit To</span>{" "}
            </label>
            <input
              {...register("ageLimitTo")}
              name="ageLimitTo"
              className="input form-control"
              id="ageLimitTo"
              type="text"
              placeholder="Enter age limit to"
            />
          </div>
          <div className=" col-md-4 ">
            <label className="label">
              <span className="label-text fw-bold">Applicable for</span>{" "}
            </label>

            <select
              {...register("applicableFor")} name="applicableFor" className="form-select"                >
              <option>Please Select</option>
              <option value={"Male and Female"}>Males and Females</option>
              <option value={"Female"}>Females</option>
              <option value={"Male"}>Males</option>
            </select>
          </div>
        </div>



        <div className=" my-lg-4">
          <label className="label">
            <span className=" fw-bold">
              Special Instruction for Job Seekers
            </span><span className="star">&#x2605;</span>
          </label>
          <div>
            <textarea
              {...register("business_description", { required: true })}
              name="business_description"
              className="input form-control"
              id="business_description"
              type="text"
              placeholder="Write Special Instruction for Job Seekers"
              maxLength={350}
            />
          </div>
        </div>

        <div className="my-lg-3">
          <label className="label">
            <span className="label-text fw-bold">Job Context</span><span className="star">&#x2605;</span>
          </label>
          <textarea
            {...register("job_context", { required: true })}
            name="job_context"
            className="input form-control "
            id="job_context"
            type="text"
            placeholder="Enter job Context"
            maxLength={250}
          />
        </div>

        <div className="my-lg-3">
          <label className="label">
            <span className="label-text fw-bold">Job Responsibilities</span><span className="star">&#x2605;</span>
          </label>
          <textarea
            name="job_respons"
            {...register("job_responst", { required: true })}
            className="input form-control "
            id="job_respons"
            type="text"
            placeholder="Enter Job Responsibilities"
            maxLength={350}
          />
        </div>

        {/* work day and time */}
        <div className=" ">
          <label className="label">
            <span className=" fw-bold me-lg-2 ">Work Day and Time</span><span className="star">&#x2605;</span>
          </label>
          <div className=" d-flex justify-content-between">
            <div className=" d-flex col-md-5 my-1">

              <label className="label">
                <span className=" fw-bold me-lg-2 ">Workday</span>
              </label>
              <select
                {...register("workday_from")} name="workday_from" className="form-select"                >
                <option>Please Select</option>
                <option value={"Sunday"}>Sunday</option>
                <option value={"Monday"}>Monday</option>
                <option value={"Tuesday"}>Tuesday</option>
                <option value={"Wednesday"}>Wednesday</option>
                <option value={"Thursday"}>Thursday</option>
                <option value={"Friday"}>Friday</option>
                <option value={"Saturday"}>Saturday</option>
              </select>

              <label className=" fw-bold mx-1">to</label>
              <select
                {...register("workday_to")} name="workday_to" className="form-select"                >
                <option>Please Select</option>
                <option value={"Thursday"}>Thursday</option>
                <option value={"Friday"}>Friday</option>
                <option value={"Saturday"}>Saturday</option>
                <option value={"Sunday"}>Sunday</option>
                <option value={"Monday"}>Monday</option>
                <option value={"Tuesday"}>Tuesday</option>
                <option value={"Wednesday"}>Wednesday</option>
              </select>

            </div>
            <div className=" d-flex col-md-5 my-1 ">

              <label className="label">
                <span className=" fw-bold me-lg-2 ">Worktime</span><span className="star">&#x2605;</span>
              </label>

              <input
                {...register("worktime_from", { required: true })}
                name="worktime_from"
                type="time"
                id="worktime_from"
                placeholder="Worktime from"
                className="input form-control"
              />

              <label className=" fw-bold mx-1">to</label><span className="star">&#x2605;</span>

              <input
                {...register("worktime_to", { required: true })}
                name="worktime_to"
                type="time"
                id="worktime_to"
                placeholder="Worktime to"
                className="input form-control"
              />

            </div>
          </div>
        </div>

        {/* salary and bonus */}
        <div className=" row my-lg-3">
          <div className=" col-lg-6">
            <label className="label">
              <span className=" fw-bold me-lg-2 ">Salary</span>
            </label>
            <div className="d-flex justify-content-between align-items-center">
              <input
                {...register("salary_from", {})}
                name="salary_from"
                type="text"
                id="salary_from"
                placeholder="Salary From"
                className="input form-control"
              />
              <label className=" fw-bold mx-1">to</label>
              <input
                {...register("salary_to", {})}
                name="salary_to"
                type="text"
                id="salary_to"
                placeholder="Salary To"
                className="input form-control"
              />
              <label className=" fw-bold mx-1">Or</label>
              <input
                {...register("salary", {})}
                name="salary"
                type="text"
                id="salary"
                placeholder="Ex. negotiable"
                className="input form-control"
              />
            </div>
          </div>

          <div className=" col-lg-3 ">
            {/* <div className="form-control w-full max-w-xs"> */}
            <div>
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Yearly Bonus</span>
              </label>
              <select
                {...register("yearly_bonus")}
                name="yearly_bonus"
                className=" form-select"
              >
                <option>Yearly Bonus</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
              </select>
            </div>
          </div>

          <div className=" col-lg-3">
            <label className="label">
              <span className=" fw-bold me-lg-2 ">Salary Review</span>
            </label>
            <select
              {...register("salary_review")}
              name="salary_review"
              className="form-select"
            >
              <option>Salary Review</option>
              <option value="Yearly">Yearly</option>
              <option value="Half Yearly">Halt Yearly</option>
            </select>
          </div>
        </div>



        {/* Benifits section */}
        <div className="row my-3">
          <label className="label">
            <span className="label-text fw-bold">Benifits</span>{" "}
          </label>

          <div className="row">
            <div className=" col-lg-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits one</span>
              </label>
              <input
                {...register("benifits_one", {})}
                name="benifits_one"
                type="text"
                id="benifits_one"
                placeholder="benifits one"
                className="input form-control"
              />
            </div>

            <div className=" col-md-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits two</span>
              </label>
              <input
                {...register("benifits_two", {})}
                name="benifits_two"
                type="text"
                id="benifits_two"
                placeholder="benifits two"
                className="input form-control"
              />
            </div>
          </div>

          <div className="row my-1">
            <div className=" col-md-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits three</span>
              </label>
              <input
                {...register("benifits_three", {})}
                name="benifits_three"
                type="text"
                id="benifits_three"
                placeholder="benifits three"
                className="input form-control"
              />
            </div>

            <div className=" col-md-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits four</span>
              </label>
              <input
                {...register("benifits_four", {})}
                name="benifits_four"
                type="text"
                id="benifits_four"
                placeholder="benifits four"
                className="input form-control"
              />
            </div>
          </div>

          <div className=" row my-1">
            <div className=" col-md-6 ">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits five</span>
              </label>
              <input
                {...register("benifits_five", {})}
                name="benifits_five"
                type="text"
                id="benifits_five"
                placeholder="benifits five"
                className="input form-control"
              />
            </div>
            <div className=" col-md-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits six</span>
              </label>
              <input
                {...register("benifits_six", {})}
                name="benifits_six"
                type="text"
                id="benifits_six"
                placeholder="benifits six"
                className="input form-control"
              />
            </div>
          </div>
        </div>


        <div className="row my-lg-2">
          <div className=" col-md-10">
            <label className="label">
              <span className=" fw-bold me-lg-2 ">Others Information</span>
            </label>
            <textarea
              {...register("others")}
              name="others"
              className="input form-control "
              id="others"
              type="text"
              maxLength={450}
            />
          </div>

          <div className=" col-md-2  ">
            <label className="label">
              <span className=" fw-bold me-lg-2 ">Job Status</span>
            </label>
            <select
              {...register("status")}
              name="status"
              className="form-select"
            >
              {/* <option>Select Job Status</option> */}
              <option defaultValue={"Active"}>Active</option>
              <option value={"Inactive"}>Inactive</option>
              <option value={"Close"}>Close</option>
            </select>
          </div>
        </div>

        <div className=" d-flex justify-content-between my-lg-3">
          <button className="btn btn-warning fw-bold">Cancel</button>
          <button type="submit" name="submit" className="custom_btn">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewJobPost;
