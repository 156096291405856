import FindJobAndStatistics from '../JobSearch/FindJobAndStatistics';
import JobCategory from '../JobCategory/JobCategory';
import HotJobs from '../HotJobs/HotJobs';
import Subscriber from '../Subscriber/Subscriber';
import useTitle from '../../hooks/useTitle';
import NewJobs from '../NewJobs/NewJobs';

const Home = () => {
    useTitle('Home');

    let refresh = window.localStorage.getItem('refresh');
    if (refresh === null) {
        window.location.reload();
        window.location.reload();
        window.location.reload();
        window.location.reload();
        window.location.reload();
        window.localStorage.setItem('refresh', "1");
    }

    return (
        <div>
            <FindJobAndStatistics></FindJobAndStatistics>
            <JobCategory></JobCategory>
            <HotJobs></HotJobs>
            <NewJobs></NewJobs>
            <Subscriber></Subscriber>
        </div>
    );
};

export default Home;