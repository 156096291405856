import React, { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { AuthContext } from "../../Authentication/AuthProvider";
import "./EmployeerProfile.css";

const EmployersProfile = () => {
  const [employerData, setEmployerData] = useState([]);
  const { user } = useContext(AuthContext);
  const email = user.email;

  const employer = useLoaderData();

  console.log("Employer : ", employer);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_CABD_SERVER}/employer/${email}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Employee Data:", data);
        setEmployerData(data);
      });
  }, [email]);

  console.log("employerData : ", employerData)

  const { id, name, companyNameEn, companyLogo, companyNameBn, estdYear, companySize, addressEng, addressBng, busiDescription, tradeLicense,
    websiteURL, contactPersonName, contactPersonDesignation, organizationType, contactPersonEmail, contactPersonPhone, } = employerData;

  return (
    <div>
      <article className="resume-wrapper text-center position-relative">
        <div className="resume-wrapper-inner text-start bg-white shadow-sm my-4 ">
          <header className="resume-header mx-4">
            <h2 className=" text-center mt-5 mb-2 fw-bold">Company Profile</h2>
            <hr />
            <div className=" d-flex justify-content-evenly ">
              {/* Employers Data */}
              <div className="col-lg-8">
                <div className="row">
                  <div className=" d-flex  ">
                    <div className=" col-lg-8">
                      <div className="primary-info col-auto">

                        <h3 className="title">{companyNameEn}</h3><p className=" my-0">({companyNameBn})</p>
                        <h5 className="title ">{addressEng} </h5><p className="my-0">({addressBng})</p>
                        <p>
                          <b>Company Type:</b> {organizationType} <br />
                          <b>Estd:</b>  {estdYear}, <span> <b>No of Empoyee:</b> {companySize} + </span> <br />
                          <b>Trade License:</b> {tradeLicense} <br />
                          <b>Email :</b> {email} <br />
                          <b>Website :</b> {websiteURL}
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Employeers Image  */}
              <div className="col-lg-4 ">
                <img className="empProfileLogo" src={companyLogo} alt="" />
              </div>
            </div>
          </header>
          <div className="resume-body my-4 mx-4">
            <section className="resume-section summary-section">
              <h2 className=""> Description</h2>
              <div className="resume-section-content">
                <p>{busiDescription}</p>
              </div>
            </section>

            <div className="row">
              <h4 className=" fw-bold">Contact Person</h4>
              <div className="resume-timeline-item-header">
                <div className=" ">
                  <p className="resume-position-title fw-bold ">
                    {contactPersonName}, {contactPersonDesignation}
                  </p>
                  <p className="">Phone : {contactPersonPhone} <br />
                    Email : {contactPersonEmail}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article >
    </div >
  );
};

export default EmployersProfile;
