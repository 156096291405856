import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

const useEmployer = (email) => {
  const [isEmployer, setIsEmployer] = useState(false);
  const [isEmployerLoading, setIsEmployerLoading] = useState(true);

  useEffect(() => {
    if (email) {
      fetch(
        `${process.env.REACT_APP_CABD_SERVER}/employerUser/${email}`)
        .then((response) => response.json())
        .then((data) => {
          setIsEmployer(data.isEmployer);
          setIsEmployerLoading(false);
        });
    }
  }, [email]);

  return [isEmployer, isEmployerLoading];
};

export default useEmployer;
