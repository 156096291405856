import { useQuery } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { useLoaderData } from "react-router-dom";
import { AuthContext } from "../../Authentication/AuthProvider";
import Loader from "../../Shared/Loader/Loader";

const UpdatePostedJob = () => {
  const { user, loading, setLoading } = useContext(AuthContext);
  const storedData = useLoaderData();

  console.log("storedData Job Details :", storedData);
  // console.log("storedData :",storedData);

  const [updatedData, setUpdatedData] = useState(storedData);

  // const imageHostKey = process.env.REACT_APP_CABD_imagebb_hostKey;

  const { data: categories, isLoading } = useQuery({
    queryKey: ["category"],
    queryFn: async () => {
      const res = await fetch(`${process.env.REACT_APP_CABD_SERVER}/jobCategories`);
      const data = await res.json();
      return data;
    },
  });

  if (loading) {
    <Loader></Loader>
  }

  // const jobPostDate = new Date().toJSON().slice(0, 10);


  console.log("Posted Job Updated Data :", updatedData);

  const handleInputChange = (event) => {
    const field = event.target.name;
    const value = event.target.value;

    const newData = { ...updatedData };
    newData[field] = value;

    setUpdatedData(newData);
  }

  const handleJobUpdate = (event) => {
    event.preventDefault();
    fetch(`${process.env.REACT_APP_CABD_SERVER}/postedJobUpdate/${storedData._id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(updatedData),
    })
      .then(res => res.json())
      .then(data => {
        console.log("Updated Data : ", data);
        if (data.modifiedCount > 0) {
          toast.success("Job's Data Updated Successfully.");
          setLoading(false);
        }
      });
  }

  return (
    <div>
      <h2 className=" text-center fw-bold my-3">Update Job Information</h2>

      <form onSubmit={handleJobUpdate}>

        <div className="row my-2">

          <div className=" col-md-5  ">
            <label className="label">
              <span className="label-text fw-bold">Job Title</span>{" "}
            </label>
            <input
              onChange={handleInputChange}
              name="jobTitle"
              id="jobTitle"
              className="input form-control"
              type="text"
              defaultValue={storedData?.jobTitle}
            />
          </div>

          <div className=" col-md-5">
            <label className="label">
              <span className="label-text fw-bold">Job Location</span>{" "}
            </label>
            <input
              onChange={handleInputChange}
              name="location"
              id="location"
              className="input form-control"
              type="text"
              defaultValue={storedData?.location}
            />
          </div>

          <div className=" col-md-2  ">
            <div className="">
              <label className="label">
                <span className=" fw-bold me-md-2 ">Job Status</span>
              </label>
              <select
                onChange={handleInputChange}
                name="status"
                id="status"
                className=" form-select"
              >
                <option defaultValue={storedData?.status}>{storedData?.status}</option>
                <option value={"Active"}>Active</option>
                <option value={"Inactive"}>Inactive</option>
                <option value={"Close"}>Close</option>
              </select>
            </div>
          </div>
        </div>



        <div className="  my-2">
          <label className="label" htmlFor="category">
            <span className="label-text fw-bold">Job Category</span>{" "}
          </label>
          <select
            onChange={handleInputChange}
            name="category"
            type="text"
            className="form-select"
          >
            <option defaultValue={storedData.category}>{storedData.category}</option>
            {categories &&
              categories.map((category, index) => (
                <option key={index} value={category._id}>{category.name}</option>
              ))}
          </select>
        </div>

        <div className="row my-2">
          <div className=" col-md-4">
            <label className="label">
              <span className="label-text fw-bold">Vacancies</span>{" "}
            </label>
            <input
              onChange={handleInputChange}
              name="vacancies"
              id="vacancies"
              className="input form-control"
              type="text"
              defaultValue={storedData?.vacancies}
            />
          </div>

          <div className="col-md-4">
            <label className="label">
              <span className="label-text fw-bold">Job Level</span>{" "}
            </label>
            <select
              onChange={handleInputChange}
              name="jobLevel"
              id="jobLevel"
              className="form-select"
            >
              <option defaultValue={storedData?.jobLevel}>{storedData?.jobLevel}</option>
              <option value={"Entry"}>Entry</option>
              <option value={"Mid"}>Mid</option>
              <option value={"Top"}>Top</option>
            </select>
          </div>

          <div className=" col-md-4">
            <label className="label">
              <span className="label-text fw-bold">Work Place</span>{" "}
            </label>
            <select
              onChange={handleInputChange}
              name="workPlace"
              id="workPlace"
              className="form-select"
            >
              <option defaultValue={storedData?.workPlace}>{storedData?.workPlace}</option>
              <option value="Work at Office">Work at Office</option>
              <option value="Work from Home">Work from Home</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>
        </div>

        <div className="row my-lg-4">
          <div className=" col-md-4">
            <label className="label">
              <span className="label-text fw-bold">Employment Status</span>{" "}
            </label>
            <select
              onChange={handleInputChange}
              name="employmentStatus"
              id="employmentStatus"
              className=" form-select "
            >
              <option defaultValue={storedData?.employmentStatus}>{storedData?.employmentStatus}</option>
              <option value={"Full Time"}>Full Time</option>
              <option value={"Part Time"}>Part Time</option>
              <option value={"Contactual Time"}>Contactual Time</option>
              <option value={"Internship"}>Internship</option>
              <option value={"Freelance"}>Freelance</option>
            </select>
          </div>

          <div className="col-md-4">
            <label className="label">
              <span className="label-text fw-bold">Dead Line</span>{" "}
            </label>
            <input
              onChange={handleInputChange}
              name="deadLine"
              id="deadLine"
              className="input form-control ms-1"
              type="date"
              defaultValue={storedData?.deadLine}
            />
          </div>

          <div className=" col-md-4">
            <label className="label">
              <span className="label-text fw-bold">Apply Status</span>{" "}
            </label>
            <select
              onChange={handleInputChange}
              name="applyStatus"
              id="applyStatus"
              className=" form-select"
            >
              <option defaultValue={storedData?.applyStatus}>{storedData?.applyStatus}</option>
              <option value="Apply Online">Apply Online</option>
              <option value="email">Email</option>
              <option value="Hard Copy">Heard Copy</option>
              <option value="Interview">Walk in Interview</option>
            </select>
          </div>
        </div>

        <div className=" row my-2">
          <div className="col-md-6 ">
            <label className="label">
              <span className="label-text fw-bold">Education Qualification</span>{" "}
            </label>
            <input
              onChange={handleInputChange}
              defaultValue={storedData?.education}
              name="education"
              className="input form-control ms-1"
              id="education"
              type="text"
            />
          </div>

          <div className="col-md-6">
            <label className="label">
              <span className="label-text fw-bold">Experience</span>{" "}
            </label>
            <input
              onChange={handleInputChange}
              defaultValue={storedData?.experience}
              name="experience"
              className="input form-control ms-1"
              id="experience"
              type="text"
            />
          </div>
        </div>


        <div className="row my-3">
          <label className="label">
            <span className="label-text fw-bold">Additional Requirements</span>{" "}
          </label>
          <div className=" col-md-4  ">
            <label className="label">
              <span className="label-text fw-bold">Age Limit From</span>{" "}
            </label>
            <input
              onChange={handleInputChange}
              defaultValue={storedData?.ageLimitFrom}
              name="ageLimitFrom"
              className="input form-control"
              id="ageLimitFrom"
              type="text"
            />
          </div>
          <div className=" col-md-4  ">
            <label className="label">
              <span className="label-text fw-bold">Age Limit To</span>{" "}
            </label>
            <input
              onChange={handleInputChange}
              defaultValue={storedData?.ageLimitTo}
              name="ageLimitTo"
              className="input form-control"
              id="ageLimitTo"
              type="text"
            />
          </div>
          <div className=" col-md-4  ">
            <label className="label">
              <span className="label-text fw-bold">Applicable for</span>{" "}
            </label>

            <select
              onChange={handleInputChange} name="applicableFor" className="form-select"  >
              <option defaultValue={storedData?.applicableFor}>{storedData?.applicableFor}</option>
              <option value={"Male and Female"}>Male and Female</option>
              <option value={"Female"}>Female</option>
              <option value={"Male"}>Male</option>
            </select>
          </div>
        </div>


        <div className=" my-lg-4">
          <label className="label">
            <span className=" fw-bold">
              Special Instruction for Job Seekers
            </span>
          </label>
          <div>
            <textarea
              defaultValue={storedData?.businessDescription}
              onChange={handleInputChange}
              name="businessDescription"
              id="businessDescription"
              className="input form-control"
              type="text"
              maxLength={350}
            />
          </div>
        </div>

        <div className="my-lg-2">
          <label className="label">
            <span className=" fw-bold me-lg-2 ">Job Context</span>
          </label>
          <textarea
            defaultValue={storedData?.jobContext}
            onChange={handleInputChange}
            name="jobContext"
            className="input form-control "
            id="jobContext"
            type="text"
            maxLength={250}
          />
        </div>

        <div className="my-lg-3">
          <label className="label">
            <span className=" fw-bold me-lg-2 ">Job responsibility</span>
          </label>
          <textarea
            onChange={handleInputChange}
            defaultValue={storedData?.jobResponst}
            name="jobResponst"
            className="input form-control "
            id="jobResponst"
            type="text"
            maxLength={350}
          />
        </div>

        <div className=" row my-lg-3">
          <div className=" col-md-6">
            <label className="label">
              <span className=" fw-bold me-lg-2 ">Salary</span>
            </label>
            <div className="d-flex justify-content-between align-items-center">
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.salaryFrom}
                name="salaryFrom"
                type="text"
                id="salaryFrom"
                className="input form-control"
              />
              <label className=" fw-bold mx-1">to</label>
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.salaryTo}
                name="salaryTo"
                type="text"
                id="salaryTo"
                className="input form-control"
              />
              <label className=" fw-bold mx-1">Or</label>
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.salary}
                name="salary"
                type="text"
                id="salary"
                className="input form-control"
              />
            </div>
          </div>

          <div className=" col-md-3 ">
            <label className="label">
              <span className=" fw-bold me-lg-2 ">Yearly Bonus</span>
            </label>
            <select
              onChange={handleInputChange}
              name="yearlyBonus"
              className=" form-select"
            >
              <option defaultValue={storedData?.yearlyBonus}>{storedData?.yearlyBonus}</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
          </div>

          <div className=" col-md-3">
            <label className="label">
              <span className=" fw-bold me-lg-2 ">Salary Review</span>
            </label>
            <select
              onChange={handleInputChange}
              name="salaryReview"
              className="form-select"
            >
              <option defaultValue={storedData?.salaryReview}>{storedData?.salaryReview}</option>
              <option value={"Yearly"}>Yearly</option>
              <option value={"Half Yearly"}>Half Yearly</option>
            </select>
          </div>
        </div>


        <div className="">
          <div className=" ">
            <label className="label">
              <span className=" fw-bold me-lg-2 ">Work Day and Time</span>
            </label>
            <div className=" d-flex justify-content-between">
              <div className=" d-flex col-md-5 my-1">

                <label className="label">
                  <span className=" fw-bold me-lg-2 ">Workday</span>
                </label>
                <select
                  onChange={handleInputChange}
                  name="workdayFrom"
                  className="form-select"
                ><option defaultValue={storedData?.workdayFrom}>{storedData?.workdayFrom}</option>
                  <option value={"Sunday"}>Sunday</option>
                  <option value={"Monday"}>Monday</option>
                  <option value={"Tuesday"}>Tuesday</option>
                  <option value={"Wednesday"}>Wednesday</option>
                  <option value={"Thursday"}>Thursday</option>
                  <option value={"Friday"}>Friday</option>
                  <option value={"Saturday"}>Saturday</option>
                </select>


                <label className=" fw-bold mx-1">to</label>
                <select
                  onChange={handleInputChange}
                  name="workdayTo"
                  className="form-select"
                ><option defaultValue={storedData?.workdayTo}>{storedData?.workdayTo}</option>
                  <option value={"Thursday"}>Thursday</option>
                  <option value={"Friday"}>Friday</option>
                  <option value={"Saturday"}>Saturday</option>
                  <option value={"Sunday"}>Sunday</option>
                  <option value={"Monday"}>Monday</option>
                  <option value={"Tuesday"}>Tuesday</option>
                  <option value={"Wednesday"}>Wednesday</option>
                </select>

              </div>
              <div className=" d-flex col-md-5 my-1 ">

                <label className="label">
                  <span className=" fw-bold me-lg-2 ">Worktime</span>
                </label>

                <input
                  onChange={handleInputChange}
                  defaultValue={storedData?.worktimeFrom}
                  name="worktimeFrom"
                  type="time"
                  id="worktimefrom"
                  className="input form-control"
                />

                <label className=" fw-bold mx-1">to</label>

                <input
                  onChange={handleInputChange}
                  defaultValue={storedData?.worktimeTo}
                  name="worktimeTo"
                  type="time"
                  id="worktimeTo"
                  className="input form-control"
                />

              </div>
            </div>
          </div>
        </div>

        <div className="row my-3">
          <label className="label">
            <span className="label-text fw-bold">Benifits</span>{" "}
          </label>

          <div className="row">
            <div className=" col-lg-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits one</span>
              </label>
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.benifitsOne}
                name="benifitsOne"
                type="text"
                id="benifitsOne"
                className="input form-control"
              />
            </div>

            <div className=" col-md-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits two</span>
              </label>
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.benifitsTwo}
                name="benifitsTwo"
                type="text"
                id="benifitsTwo"
                className="input form-control"
              />
            </div>
          </div>

          <div className="row my-1">
            <div className=" col-md-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits three</span>
              </label>
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.benifitsThree}
                name="benifitsThree"
                type="text"
                id="benifitsThree"
                className="input form-control"
              />
            </div>

            <div className=" col-md-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits four</span>
              </label>
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.benifitsFour}
                name="benifitsFour"
                type="text"
                id="benifitsFour"
                className="input form-control"
              />
            </div>
          </div>

          <div className=" row my-1">
            <div className=" col-md-6 ">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits five</span>
              </label>
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.benifitsFive}
                name="benifitsFive"
                type="text"
                id="benifitsFive"
                className="input form-control"
              />
            </div>
            <div className=" col-md-6">
              <label className="label">
                <span className=" fw-bold me-lg-2 ">Benifits six</span>
              </label>
              <input
                onChange={handleInputChange}
                defaultValue={storedData?.benifitsSix}
                name="benifitsSix"
                type="text"
                id="benifitsSix"
                className="input form-control"
              />
            </div>
          </div>
        </div>

        <div className="my-lg-2">
          <div className="">
            <label className="label">
              <span className=" fw-bold me-lg-2">Others Information</span>
            </label>
            <textarea
              onChange={handleInputChange}
              defaultValue={storedData?.others}
              name="others"
              className="input form-control "
              id="others"
              type="text"
              maxLength={450}
            />
          </div>
        </div>


        <div className=" d-flex justify-content-between my-lg-3">
          <button type="reset" className="btn btn-warning fw-bold">Reset</button>
          {/* <button type="cancel" onclick="window.location='/';return false;">Cancel</button> */}

          <button type="submit" name="submit" className="custom_btn">Save</button>
        </div>

      </form>
    </div>
  );
};

export default UpdatePostedJob;