import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import { AuthContext } from "../Authentication/AuthProvider";
import Loader from "../Shared/Loader/Loader";
import "./FindJob.css";

const FindJobHomeResult = ({ jobList }) => {
  useTitle("FindJob");

  const [search, setSearch] = useState("");
  const [showJobs, setShowJobs] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const searchRef = useRef();
  const { searchData, searchOrganizationData, searchLocationtionData, setSearchData, setSearchLocationtionData, setSearchOrganizationData } = useContext(AuthContext);

  const { register, handleSubmit, formState: { errors }, } = useForm();

  const pages = Math.ceil(count / size);

  const foundJobs = showJobs.length;
  console.log("FindJobHomeResult foundJobs :", foundJobs);

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_CABD_SERVER}/homeJobSearch/${searchData.jobTitle}/${searchLocationtionData.location}/${searchOrganizationData.orgaType}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log(" Home Job Search Result :", data);
  //       setShowJobs(data);
  //       setSearchData("");
  //       setSearchLocationtionData("");
  //       setSearchOrganizationData("");
  //       // console.log(" Home Job Search Result22 :", data);
  //     });
  // }, []);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_CABD_SERVER}/homeJobSearch?jobTitle=${searchData.jobTitle}&location=${searchLocationtionData.location}
    &orgaType=${searchOrganizationData.orgaType}&page=${page}&size=${size}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setShowJobs(data.jobs);
        setCount(data.totalJobs);
        setSearchData("");
        setSearchLocationtionData("");
        setSearchOrganizationData("");
      });
  }, [page, size]);

  // useEffect(() => {
  //   const apiUrl = `${process.env.REACT_APP_CABD_SERVER}/homeJobSearch/${searchData.jobTitle}/${searchLocationtionData.location}/${searchOrganizationData.orgaType}?page=${page}&size=${size}`;

  //   fetch(apiUrl)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setShowJobs(data.jobs);
  //       setCount(data.totalJobs);
  //       setSearchData("");
  //       setSearchLocationtionData("");
  //       setSearchOrganizationData("");
  //     });
  // }, [page, size]);




  const { data: categories, isLoading } = useQuery({
    queryKey: ["category"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_CABD_SERVER}/jobCategories`
      );
      const data = await res.json();
      return data;
    },
  });

  const { data: jobs = [], refetch } = useQuery({
    queryKey: ["jobs"],
    queryFn: async () => {
      const respone = await fetch(
        `${process.env.REACT_APP_CABD_SERVER}/jobs`
      );
      const data = respone.json();
      return data;
    },
  });



  if (isLoading) {
    return <Loader></Loader>;
  }


  const handleNextPage = () => {
    // if (page < pages - 1) {
    setPage(page + 1)
    // }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };


  return (
    <div className="row">
      <div className="col-md-12">
        <div className="grid search">
          <div className="grid-body">
            <div className="row">


              <div className="mt-md-0 mt-5 mx-md-auto">


                <h2 className=" fs-4 fw-bold text-center">
                  {foundJobs ? <> Showing Job Search Result</> : <></>}
                </h2>

                <div className="padding"></div>


                {/* job search result table */}

                {
                  foundJobs ?
                    <>
                      <div className="table-responsive">
                        <table className="table table-hover  table-bordered">
                          <thead>
                            <tr className="table-secondary text-center">
                              <th>SL</th>
                              <th>Job Title</th>
                              <th>Institution</th>
                              <th>Organization Type</th>
                              <th>Work Place</th>
                              <th>Posted</th>
                              <th>DeadLine</th>
                              <th>Salary</th>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              // jobs.map((job, index) =>
                              showJobs.map((job, index) => (
                                <tr key={job._id} className="tableData">
                                  <td className="text-center">{index + 1 + page * size}</td>
                                  <td className="fw-bold">
                                    <Link
                                      className=" text-decoration-none text-primary "
                                      to={`/jobDetails/${job._id}`}
                                    >
                                      {job.jobTitle}
                                    </Link>
                                  </td>
                                  {/* <td>{job.organization}</td> */}
                                  <td className=" d-flex justify-content-between align-items-center">
                                    {job.organization}
                                    <img className=" rounded-5" src={job?.companyLogo} alt="" />
                                  </td>
                                  <td>{job.orgaType}</td>
                                  <td>{job.location}</td>
                                  <td>{job.postDate}</td>
                                  <td>{job.deadLine}</td>
                                  <td>৳{job.salaryTo}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </>
                    :
                    <>
                      <h3 className=" text-center fw-bold">No job is found, please fill at least one search field</h3>
                    </>}

                {foundJobs > 0 ? (
                  <>
                    {foundJobs > 1 ? (
                      <>
                        <p className=" fs-5 text-success   text-center fw-bold">
                          {count} jobs found in this category
                        </p>
                      </>
                    ) : (
                      <>
                        <p className=" fs-5 text-success   text-center fw-bold">
                          {count} job found in this category
                        </p>
                      </>
                    )}


                  </>
                ) : (
                  <></>
                )}

                {/* pagnation start */}
                {/* {(pages > 1) && <> */}
                {/* <div className="col-lg-8 mx-auto  d-flex justify-content-between align-items-center" >


                  <div className=" d-flex">
                    <button className="btn btn-primary btn-sm fw-bold" onClick={handlePreviousPage}> <b className=""> &#8249;</b>Prev</button>
                    <button className="btn btn-primary btn-sm ms-3 fw-bold" onClick={handleNextPage}>Next&#8250;</button>
                  </div>

                  <div className=" d-flex justify-content-center align-items-center">
                    <h6 className=" mt-1 fw-bold fst-italic">Jobs_per_page </h6>
                    <select className=" jobsPerPage ms-1" onChange={event => setSize(event.target.value)}>
                      <option value="10">10</option>
                      <option value="20" selected>20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>

                  </div>


                  <div className=" pageBtn d-flex justify-content-center align-items-center my-1">
                    <h5 className=" me-2">Pages:  </h5>
                    {
                      [...Array(pages).keys()].map(number => {
                        if (pages > 6 && number === 5 && pages > 7) {
                          return <p className=" fw-bold" key={number}>...</p>;
                        } else if (pages > 6 && number > 5 && number !== pages - 1) {
                          return null;
                        } else {
                          return (
                            <h5
                              key={number}
                              className={page === number && 'selected'}
                              onClick={() => setPage(number)}
                            >
                              {number + 1}&nbsp;&nbsp;
                            </h5>
                          );
                        }
                      })
                    }
                  </div>
                </div> */}

                {/* </>} */}

                {/* pagnation end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindJobHomeResult;
