import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaFile, FaList, FaSearch, FaTh } from "react-icons/fa";
import { Link } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import "./FindJob.css";

const FindAllJob = () => {
  useTitle("FindJob");
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  const { formState: { errors }, } = useForm();

  //pagnation work code
  const [allJobs, setJobs] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  const pages = Math.ceil(count / size);


  useEffect(() => {
    fetch(`${process.env.REACT_APP_CABD_SERVER}/jobSearch?search=${search}&page=${page}&size=${size}`)
      .then((response) => response.json())
      .then((data) => {
        setCount(data.count);
        console.log("Find all Jobs:", data.count);
        setJobs(data.job);
      });
  }, [search, page, size]);

  console.log("page", page);
  const handleSearch = () => {
    setSearch(searchRef.current.value);
    searchRef.current.value = "";
  };

  const { data: categories, isLoading } = useQuery({
    queryKey: ["category"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_CABD_SERVER}/jobCategories`
      );
      const data = await res.json();
      return data;
    },
  });

  const { data: jobs = [], refetch } = useQuery({
    queryKey: ["jobs"],
    queryFn: async () => {
      const respone = await fetch(`${process.env.REACT_APP_CABD_SERVER}/jobs`);
      const data = respone.json();
      return data;
    },
  });

  const handleNextPage = () => {
    if (page < pages - 1) {
      setPage(page + 1)
    }
  };

  const handlePreviousPage = () => {

    if (page > 0) {
      setPage(page - 1);
    }
  };



  return (
    <div className="row">
      <div className="col-md-12">
        <div className="grid search">
          <div className="grid-body">
            <div className="row">

              <div className=" mt-md-0 mt-5 mx-auto">
                <h3 className=" text-center fw-bold">Find the job you need</h3>
                {/* <hr /> */}

                <div className="col-md-6 mx-auto">
                  <div className="input-group">
                    <input
                      ref={searchRef}
                      name="inputSearch"
                      id="inputSearch"
                      type="text"
                      className="form-control"
                      placeholder="input search data"
                    />

                    <span className="input-group-btn">
                      <button onClick={() => handleSearch()} className="custom_btn" type="button" >
                        <FaSearch className="" />
                        Search
                      </button>
                    </span>
                  </div>
                </div>

                <h5 className=" text-center fw-bold my-3">Total {count} Jobs Found</h5>

                <div className="padding"></div>

                <div className="table-responsive">
                  <table className="table table-hover  table-bordered">
                    <thead>
                      <tr className="table-secondary text-center">
                        <th colSpan={1}>SL</th>
                        <th colSpan={3}>Title</th>
                        <th colSpan={1}>Organization</th>
                        <th colSpan={3}>Work Place</th>
                        <th colSpan={3}>Posted</th>
                        <th colSpan={3}>DeadLine</th>
                        <th colSpan={2}>Salary</th>
                      </tr>
                    </thead>

                    <tbody>
                      {allJobs.map((job, index) => (
                        <tr key={job._id} className="tableData">
                          <td className=" fw-bold text-center" colSpan={1}>{index + 1 + page * size}</td>
                          <td className="fw-bold" colSpan={3}>
                            <Link className="text-decoration-none text-primary" to={`/jobDetails/${job._id}`}>
                              {job.jobTitle}
                            </Link>
                          </td>
                          <td colSpan={3} className=" d-flex justify-content-between align-items-center">
                            {job.organization}
                            <img className=" rounded-5" src={job?.companyLogo} alt="" />
                          </td>
                          <td colSpan={3}>{job.location}</td>
                          <td colSpan={3} className=" text-center">{job.postDate}</td>
                          <td colSpan={3} className=" text-center">{job.deadLine}</td>
                          <td colSpan={2} className=" text-center">৳{job.salaryTo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>



                {/* pagnation start */}

                <div className="col-lg-8 mx-auto  d-flex justify-content-between align-items-center" >

                  <div className=" d-flex">
                    <button className="btn btn-primary btn-sm fw-bold" onClick={handlePreviousPage}> <b className=""> &#8249;</b>Prev</button>
                    <button className="btn btn-primary btn-sm ms-3 fw-bold" onClick={handleNextPage}>Next&#8250;</button>
                  </div>

                  <div className=" d-flex justify-content-center align-items-center">
                    <h6 className=" mt-1 fw-bold fst-italic">Jobs_per_page </h6>
                    <select className="  jobsPerPage ms-1" onChange={event => setSize(event.target.value)}>
                      <option value="10">10</option>
                      <option value="20" selected>20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>

                  </div>


                  <div className=" pageBtn d-flex justify-content-center align-items-center my-1">
                    <h5 className=" me-2">Pages:  </h5>
                    {
                      [...Array(pages).keys()].map(number => {
                        if (pages > 6 && number === 5 && pages > 7) {
                          return <p className=" fw-bold" key={number}>...</p>;
                        } else if (pages > 6 && number > 5 && number !== pages - 1) {
                          return null;
                        } else {
                          return (
                            <h5
                              key={number}
                              className={page === number && 'selected'}
                              onClick={() => setPage(number)}
                            >
                              {number + 1}&nbsp;&nbsp;
                            </h5>
                          );
                        }
                      })
                    }

                  </div>
                </div>
                {/* pagnation end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindAllJob;
